import React, { useState, useEffect, useCallback, useContext } from "react";
import FileViewer from "./FileViewer";
import FileDetails from "./FileDetails";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useAuth from "../stores/authStore";
import { auth, storage } from "../helper/firebaseClient";
import { api } from "../utils/axios-instance";
import axios from "axios";
import Loading from "./Loading";
import fileContext from "../context/fileContext";
import { useLocation } from "react-router-dom";
import { ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import toast, { Toaster } from "react-hot-toast";

const FileView = ({ fileInfo, closeDrawer, sharedFileInfo }) => {
  const CACHE_NAME = "blob-cache";
  console.log({ fileInfo });
  const MAX_CACHE_AGE = 7 * 24 * 60 * 60 * 1000; // Cache duration in milliseconds (1 week)

  const screenshotDetected = useAuth((state) => state.screenshotDetected);
  const screenshotAlert = useAuth((state) => state.screenshotAlert);
  const profileData = useAuth((state) => state.profileData);
  
  const [isOpen, setIsOpen] = useState(false);
  const [preUrl, setPreUrl] = useState("");
  const [signedUrl, setSignedUrl] = useState("");
  const [loadingUrl, setLoadingUrl] = useState(true);
  const [saving, setSaving] = useState(false);
  const [securityDetails, setSecurityDetails] = useState({});
  const location = useLocation();

  const context = useContext(fileContext);
  console.log({ fileInfo });
  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    closeDrawer();
  };

  useEffect(() => {
    if (screenshotDetected) {
      screenshotAlert(fileInfo.id);
    }
  }, [screenshotDetected, fileInfo.id, screenshotAlert]);

  useEffect(() => {
    openDialog();
  }, []);

  /**
   * Get the cached response for the file
   * @param {string} fileIdentfier - The file identifier
   * @returns {Promise<Response | null>} - The cached response or null if not found
   */
  const getCacheEntry = useCallback(
    async (fileIdentifier) => {
      const cache = await caches.open(CACHE_NAME);
      const response = await cache.match(fileIdentifier);
      if (!response) return null;

      const dateHeader = response.headers.get("sw-cache-date");
      if (!dateHeader) return null;

      const cacheTime = new Date(dateHeader).getTime();
      const currentTime = Date.now();

      if (currentTime - cacheTime > MAX_CACHE_AGE) {
        await cache.delete(fileIdentifier);
        return null;
      }

      return response;
    },
    [MAX_CACHE_AGE]
  );

  const getPresignedUrl = useCallback(async () => {
    try {
      setLoadingUrl(true);

      const token = await auth.currentUser.getIdToken();

      // Open the cache storage
      const cache = await caches.open(CACHE_NAME);

      const fileIdentifier = `${fileInfo.id}-${token}`;

      // Fetch presigned URL and last modified date from the server
      const body = {
        latitude: 18.44623721673684,
        longitude: 73.82762833796289,
        idToken: token,
      };

      if (fileInfo.currVersion !== "") {
        body["version"] = fileInfo.currVersion;
      }

      console.log(body);

      const serverResponse = await api.post(
        `/file/getPresigned/${fileInfo.id}`,
        body
      );

      setSecurityDetails(serverResponse.data.security_details || null);

      const fileVersionId = serverResponse.data.versionId;
      const url = serverResponse.data.signed_url;

      // Check if the file is already in the cache
      const cachedResponse = await getCacheEntry(fileIdentifier);
      if (cachedResponse) {
        console.log("Cache Found");
        const cacheVersionId = cachedResponse.headers.get("x-file-version-id");

        if (cacheVersionId === fileVersionId) {
          console.log("Cached Hit");
          const cachedBlob = await cachedResponse.blob();
          setPreUrl(URL.createObjectURL(cachedBlob));
          setLoadingUrl(false);
          return;
        } else {
          console.log("Cached Miss");
          // Invalidate stale cache
          await cache.delete(fileIdentifier);
        }
      }

      console.log("Cache miss: Fetching new data from server");
      setSignedUrl(url);

      // Cache miss: Fetch new data from server
      const response = await axios.get(url, { responseType: "arraybuffer" });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Cache the new file
      const responseToCache = new Response(blob, {
        headers: {
          "Content-Type": response.headers["content-type"],
          "sw-cache-date": new Date().toISOString(),
          "x-file-version-id": fileVersionId,
        },
      });
      await cache.put(fileIdentifier, responseToCache);

      const dataUrl = URL.createObjectURL(blob);
      setPreUrl(dataUrl);
    } catch (error) {
      console.error("Error while fetching presigned URL:", error);
    } finally {
      setLoadingUrl(false);
    }
  }, [fileInfo.id, getCacheEntry]);

  useEffect(() => {
    if (fileInfo.mimetype.includes("video")) {
      setLoadingUrl(false);
      setPreUrl(fileInfo.download_url);
      return;
    }
    getPresignedUrl();
  }, [fileInfo.id, getPresignedUrl, fileInfo.mimetype, fileInfo.download_url]);

  if (loadingUrl) {
    return <Loading />;
  }

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Day of the month
    const month = date.toLocaleString("default", { month: "short" }); // Short month name
    const year = date.getFullYear(); // Full year

    let hours = date.getHours(); // Hour in 24-hour format
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Minutes with leading zero
    const period = hours >= 12 ? "pm" : "am"; // am/pm

    // Convert to 12-hour format
    hours = hours % 12 || 12;

    return `${day} ${month}, ${year}\n${hours}:${minutes}${period}`;
  };

  const saveVersion = () => {
    const date = formatDate(new Date());
    return new Promise(async (resolve, reject) => {
      const id = uuidv4();
      const fileRef = ref(storage, `files/${profileData.org}/${id}`);

      const uploadTask = uploadBytesResumable(fileRef, context.currFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error("File upload error:", error);
          reject(error);
        },
        () => {
          console.log("File version saved successfully");
          resolve();
        }
      );

      const token = await auth.currentUser.getIdToken();
      const versionData = {
        id: id,
        lastUpdate: date,
      };
      const res = await api.post(`file/save-file-version/${fileInfo.id}/`, {
        versionData: versionData,
        idToken: token,
      });

      context.setFilteredData((prevData) =>
        prevData.map((data) =>
          data.id === fileInfo.id
            ? { ...data, versions: [...data.versions, versionData] }
            : data
        )
      );

      toast.success("Version saved successfully");
    });
  };

  return (
    <div>
      <Toaster reverseOrder={false} position="bottom-left" />
      {!context.isMultiSelect && location.pathname !== "/chat" ? (
        <Dialog
          open={isOpen}
          onClose={closeDialog}
          fullScreen
          disableEscapeKeyDown
        >
          <DialogContent
            style={{
              backgroundColor: "#F7F8FA",
              margin: 0,
              padding: 0,
            }}
          >
            <div className={`flex flex-col`}>
              <div className="w-full">
                <FileDetails
                  fileInfo={fileInfo}
                  sharedFileInfo={sharedFileInfo}
                  closeDrawer={closeDrawer}
                  preUrl={preUrl}
                  signedUrl={signedUrl}
                  saving={saving}
                  setPreUrl={setPreUrl}
                  securityDetails={securityDetails}
                  saveVersion={saveVersion}
                />
              </div>
              <div className={`w-full ${screenshotDetected ? "blur" : ""}`}>
                {loadingUrl && (
                  <div className="text-center pt-20">Fetching URL...</div>
                )}
                {!loadingUrl && preUrl ? (
                  <FileViewer
                    preUrl={preUrl}
                    mimetype={fileInfo.mimetype}
                    signedUrl={signedUrl}
                    fileName={fileInfo.name}
                    fileId={fileInfo.id}
                    setSaving={setSaving}
                    saving={saving}
                    securityDetails={securityDetails}
                    currVersion={fileInfo.currVersion}
                  />
                ) : (
                  <div className="text-center pt-20">
                    You do not have access to the file.
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <div
          className={`flex flex-col bg-white m-2 ${location.pathname === "/chat" ? "w-full mr-2" : "w-[45vw]"}`}
        >
          <div>
            <FileDetails
              fileInfo={fileInfo}
              sharedFileInfo={sharedFileInfo}
              closeDrawer={closeDrawer}
              preUrl={preUrl}
              signedUrl={signedUrl}
              saving={saving}
            />
          </div>
          <div className={`w-full ${screenshotDetected ? "blur" : ""}`}>
            {loadingUrl && (
              <div className="text-center pt-20">Fetching URL...</div>
            )}
            {!loadingUrl && preUrl ? (
              <FileViewer
                preUrl={preUrl}
                mimetype={fileInfo.mimetype}
                signedUrl={signedUrl}
                fileName={fileInfo.name}
                fileId={fileInfo.id}
                setSaving={setSaving}
              />
            ) : (
              <div className="text-center pt-20">
                You do not have access to the file.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileView;
