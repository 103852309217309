import React, { useContext, useEffect } from "react";
import TextEditor from "./FileEditors/TextEditor";
import CustomFileViewer from "./CustomFileViewer";
import SpreadsheetComponent from "./FileEditors/SpreadSheetViewer";
import fileContext from "../context/fileContext";
import ImageEditor from "./FileEditors/ImageEditor";
import PdfEditor from "./FileEditors/PdfEditor";
import VideoEditor from "./FileEditors/VideoEditor";

const FileViewer = ({
  preUrl,
  mimetype,
  signedUrl,
  fileName,
  fileId,
  saving,
  setSaving,
  securityDetails,
  currVersion,
}) => {
  const context = useContext(fileContext);
  const containerStyles = {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iframeStyles = {
    width: "100%",
    height: context.isMultiSelect ? "65vh" : "92vh",
  };

  const overlayStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    width: "25%",
    backgroundColor: "inherit",
    zIndex: 1,
  };

  let viewerComponent;

  switch (mimetype) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      viewerComponent = (
        <TextEditor
          setSaving={setSaving}
          preUrl={preUrl}
          fileName={fileName}
          fileId={fileId}
          mimetype={mimetype}
          saving={saving}
          securityDetails={securityDetails}
          currVersion={currVersion}
        />
      );
      break;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "text/csv":
      viewerComponent = (
        <CustomFileViewer preUrl={signedUrl} mimetype={mimetype} />
      );
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      viewerComponent = (
        <SpreadsheetComponent
          preUrl={preUrl}
          mimetype={mimetype}
          fileName={fileName}
          fileId={fileId}
          setSaving={setSaving}
          securityDetails={securityDetails}
          currVersion={currVersion}
        />
      );
      break;

    case "application/pdf":
      viewerComponent = (
        <PdfEditor
          setSaving={setSaving}
          preUrl={preUrl}
          fileId={fileId}
          fileName={fileName}
        />
      );
      break;

    case "video/mp4":
    case "video/quicktime":
    case "video/x-msvideo":
    case "video/x-ms-wmv":
      viewerComponent = (
        <VideoEditor preUrl={preUrl} fileName={fileName} fileId={fileId} />
      );
      break;
    default:
      viewerComponent = (
        <div style={containerStyles}>
          {mimetype.split("/")[0] === "image" ? (
            <ImageEditor
              fileId={fileId}
              fileName={fileName}
              preUrl={preUrl}
              setSaving={setSaving}
              mimetype={mimetype}
            />
          ) : (
            <iframe title="Document" src={preUrl} style={iframeStyles} />
          )}
          <div style={overlayStyles}></div>
        </div>
      );
  }

  return (
    console.log(preUrl, mimetype, signedUrl, fileName, fileId),
    (
      <div
        style={containerStyles}
        className=" overflow-y-scroll scrollbar-hide"
      >
        {viewerComponent}
      </div>
    )
  );
};

export default FileViewer;
