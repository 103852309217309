import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { useDarkMode } from "../context/darkModeContext";
import { useLocation } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../helper/firebaseClient";
import useAuth from "../stores/authStore";

const LatestActivities = () => {
  const { darkMode } = useDarkMode();
  const [selectedValue, setSelectedValue] = useState("all");
  const [logs, setLogs] = useState([]);
  const location = useLocation();
  const isUserProfile = location.pathname.includes("/profile");
  const profileData = useAuth((state) => state.profileData);
  // Attach realtime listener
  useEffect(() => {
    const orgId = profileData?.org;

    const logsRef = collection(db, "access_logs", "organisations", orgId);

    const logsQuery = query(logsRef, orderBy("timestamp", "desc"), limit(25));

    const unsubscribe = onSnapshot(logsQuery, (snapshot) => {
      const logs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLogs(logs);
      console.log("Logs:", logs);
    });

    return () => {
      unsubscribe();
    };
  }, [profileData?.org]);

  const formatTimestamp = (timestamp) => {
    // Convert Firestore timestamp to milliseconds
    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;

    const options = {
      timeZone: "Asia/Kolkata", // Adjust this to your desired time zone
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    // Return the formatted date string
    return new Date(milliseconds).toLocaleString("en-IN", options);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    console.log("Selected Value:", value);
  };

  const skeletons = [];
  for (let i = 0; i < 4; i++) {
    skeletons.push(
      <div key={i} className="p-3 border-b rounded-lg flex items-center gap-2">
        <Skeleton
          key={i}
          variant="circular"
          width={24}
          height={24}
          className="mr-2"
        />

        <span className="w-full">
          <Skeleton className="w-3/5" height={20} />
          <Skeleton className="w-2/5" height={20} />
        </span>
      </div>
    );
  }

  return (
    <div className={`${isUserProfile ? "w-full" : "w-full"}`}>
      <Paper
        elevation={isUserProfile ? 0 : 1}
        className="h-48 overflow-y-hidden"
      >
        <div
          className={`flex justify-between items-center p-4 ${
            darkMode ? "bg-gray-600 text-gray-200 " : " "
          }`}
        >
          <span className="flex flex-row items-center gap-1">
            <p className="text-sm font-semibold text-gray-500">
              Latest Activities
            </p>
            <select
              className={`text-sm text-gray-400 bg-inherit appearance-none mx-5 px-2 py-1 border border-gray-300 rounded-md shadow-sm  focus:outline-none  ${
                darkMode
                  ? "bg-gray-500 text-gray-200"
                  : "bg-white text-gray-400"
              }`}
              onChange={handleSelectChange}
              value={selectedValue}
              style={{ outline: "none" }}
            >
              <option value="all">All</option>
              <option value="file_access">Access</option>
              {/* <option value="screenshot">Screenshot</option> */}
              <option value="download">Download</option>
            </select>
          </span>
        </div>

        <div className="h-56 pb-8 overflow-y-scroll scrollbar-hide">
          {logs.length ? (
            logs
              .filter((log) => {
                if (selectedValue === "all") {
                  return true;
                } else {
                  return log.event === selectedValue;
                }
              })
              .map((log, index) => (
                <div key={index} className="my-1">
                  <span className="flex flex-row gap-2 p-2">
                    <Tooltip title={log.user} arrow>
                      <Avatar
                        src={log.profile_pic}
                        alt="owner pic"
                        sx={{ width: 25, height: 25 }}
                      />
                    </Tooltip>
                    <span>
                      <p className="text-sm">
                        <span className="font-semibold">{log.username}</span>{" "}
                        {log.event === "screenshot"
                          ? "took Screenshot of"
                          : log.event === "download"
                            ? "downloaded"
                            : log.event === "edit"
                              ? "edited"
                              : "accessed"}{" "}
                        <span className="font-semibold">
                          {log.file_name.split("_TS=")[0]}
                        </span>{" "}
                        file.
                      </p>
                      <p className="text-sm text-gray-400 mt-2">
                        {formatTimestamp(log.timestamp)}
                      </p>
                    </span>
                  </span>
                </div>
              ))
          ) : (
            <div className="h-56 overflow-y-scroll scrollbar-hide">
              {skeletons}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default LatestActivities;
